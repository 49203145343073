import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import French from '@uppy/locales/lib/fr_FR'
import Uppy from '@uppy/core'
import XHRUpload  from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'

Object.assign(French.strings, {
  dropHereOr: 'Déposer votre CV ici ou %{browse}',
  dropHint: 'Déposez votre CV ici',
  dropPasteBoth: 'Déposer votre CV ici, coller ou %{browse}',
  dropPasteFiles: 'Déposer votre CV ici, coller ou %{browse}',
  dropPasteFolders: 'Déposer votre CV ici, coller ou %{browse}',
  dropPasteImportBoth: 'Déposer votre CV ici, coller, %{browse} ou importer de',
  dropPasteImportFiles: 'Déposer votre CV ici, coller, %{browse} ou importer de',
  dropPasteImportFolders: 'Déposer votre CV ici, coller, %{browse} ou importer de'
})

function allow (config) {
  const a = config.allow
  if (a && a.length > 0) {
    return a
      .split(',')
      .map(function (ext) { return '.' + ext })
  }
}

// @params size, allow, uid
window.Uploader = function (config) {
  new Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      maxFileSize: config.size,
      allowedFileTypes: allow(config)
    },
    meta: {
      email: config.email
    },
    locale: French // @todo
  })
    .use(Dashboard, {
      inline: true,
      target: '#drag-drop-area',
      proudlyDisplayPoweredByUppy: false,
      locale: {
        strings: config.locale
      }
    })
    .use(XHRUpload, {
      endpoint: '/',
      fieldName: 'resume'
    })
    .on('complete', function (result) {
      if (result.successful.length && !result.failed.length) {
        window.location.pathname = window.location.pathname + 'success'
      }
    })
}
